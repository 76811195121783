/* eslint-env browser */

(function (
  window,
  overwriteOptions,
  baseUrl,
  apiUrlPrefix,
  version,
  defaultNamespace,
  sendError,
  warn
) {
  try {
    /////////////////////
    // PREDEFINED VARIABLES FOR BETTER MINIFICATION
    //

    // This seems like a lot of repetition, but it makes our script available for
    // multple destination which prevents us to need multiple scripts. The minified
    // version stays small.
    var undefinedVar = undefined;
    var trueVar = true;
    var falseVar = false;
    var trueText = "true";
    var https = "https:";
    var pageviewText = "pageview";
    var eventText = "event";
    var slash = "/";
    var protocol = https + "//";
    var con = window.console;
    var doNotTrack = "doNotTrack";
    var nav = window.navigator;
    var loc = window.location;
    var locationHostname = loc.host;
    var doc = window.document;
    var userAgent = nav.userAgent;
    var notSending = "Not sending request ";
    var notSendingWhen = notSending + "when ";
    var fetchedHighEntropyValues = falseVar;
    var encodeURIComponentFunc = encodeURIComponent;
    var decodeURIComponentFunc = decodeURIComponent;
    var stringify = JSON.stringify;
    var thousand = 1000;
    var addEventListenerFunc = window.addEventListener;
    var fullApiUrl = protocol + apiUrlPrefix + baseUrl;
    var documentElement = doc.documentElement || {};
    var language = "language";
    var Height = "Height";
    var Width = "Width";
    var scroll = "scroll";
    var uaData = nav.userAgentData;
    var scrollHeight = scroll + Height;
    var offsetHeight = "offset" + Height;
    var clientHeight = "client" + Height;
    var clientWidth = "client" + Width;
    var pagehide = "pagehide";
    var platformText = "platform";
    var platformVersionText = "platformVersion";
    var docsUrl = "https://docs.simpleanalytics.com";
    var pages = 0;
    var isBotAgent =
      /(bot|spider|crawl)/i.test(userAgent) && !/(cubot)/i.test(userAgent);


    // Find the script element where options can be set on
    var scriptElement =
      doc.currentScript || doc.querySelector('script[src*="' + baseUrl + '"]');

    /////////////////////
    // HELPER FUNCTIONS
    //

    // A simple log function so the user knows why a request is not being send
    warn = function () {
      // 1. Convert args to a normal array
      var args = [].slice.call(arguments);

      // 2. Prepend log prefix
      args.unshift("Simple Analytics:");

      // 3. Pass along arguments to console.warn
      // Function.prototype.apply.call is needed for Internet Explorer
      return Function.prototype.apply.call(con.warn, con, args);
    };

    var warnInFunction = function (name, error) {
      warn("Error in your " + name + " function:", error);
    };

    var hasProp = function (obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop);
    };

    var isString = function (string) {
      return typeof string == "string";
    };

    var filterRegex = function (item) {
      return item.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    var attr = function (scriptElement, attribute) {
      return scriptElement && scriptElement.getAttribute("data-" + attribute);
    };

    var convertCommaSeparatedToArray = function (csv) {
      return Array.isArray(csv)
        ? csv
        : isString(csv) && csv.length
        ? csv.split(/, ?/)
        : [];
    };

    var isObject = function (object) {
      return object && object.constructor === Object;
    };

    var assign = function () {
      var to = {};
      var arg = arguments;
      for (var index = 0; index < arg.length; index++) {
        var nextSource = arg[index];
        if (isObject(nextSource)) {
          for (var nextKey in nextSource) {
            if (hasProp(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    };

    var settings = window.sa_settings;
    var logSettings = settings || Object.keys(overwriteOptions).length;

    // Merge overwriteOptions with sa_settings
    overwriteOptions = assign(overwriteOptions, settings);

    if (logSettings) warn("Settings", overwriteOptions);


    var collectMetricByString = function (metricAbbreviation) {
      return true;
    };

    var now = Date.now;

    var uuid = function () {
      var cryptoObject = window.crypto || window.msCrypto;
      var emptyUUID = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
      var uuidRegex = /[018]/g;

      try {
        return emptyUUID.replace(uuidRegex, function (c) {
          return (
            c ^
            (cryptoObject.getRandomValues(new Uint8Array(1))[0] &
              (15 >> (c / 4)))
          ).toString(16);
        });
      } catch (error) {
        return emptyUUID.replace(uuidRegex, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c < 2 ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      }
    };

    var isFunction = function (func) {
      return typeof func == "function";
    };

    // Define namespace for the library
    var namespaceText = "namespace";
    var namespace =
      overwriteOptions[namespaceText] ||
      attr(scriptElement, namespaceText) ||
      defaultNamespace;


    var isBoolean = function (value) {
      return !!value === value;
    };

    // By default we allow source, medium in the URLs. With strictUtm enabled
    // we only allow it with the utm_ prefix: utm_source, utm_medium, ...
    var strictUtm =
      overwriteOptions.strictUtm ||
      attr(scriptElement, "strict-utm") == trueText;

    var getQueryParams = function (ignoreSource) {
      return (
        loc.search
          .slice(1)
          .split("&")
          .filter(function (keyValue) {
            var ignore = ignoreSource || !collectMetricByString("ut");

            if (ignore) return falseVar;
            var regex =
              "^((utm_)" +
              (strictUtm ? "" : "?") +
              "(source|medium|content|term|campaign)" +
              (strictUtm ? "" : "|ref") +
              ")=";

            // The prefix "utm_" is optional with "strictUtm" disabled
            // "ref" is only collected when "strictUtm" is disabled
            return new RegExp(regex).test(keyValue);
          })
          .join("&") || undefinedVar
      );
    };


    /////////////////////
    // Warn when using script twice
    //

    // Only load our script once, customers can still send multiple page views
    // with the sa_pageview function if they turn off auto collect.
    var loadedVariable = namespace + "_loaded";
    if (window[loadedVariable] == trueVar) return warn(notSending + "twice");
    window.sa_event_loaded = trueVar;
    window[loadedVariable] = trueVar;

    /////////////////////
    // SEND DATA VIA OUR PIXEL
    //

    // Send data via image
    var sendData = function (data, callback, onlyThisData) {
      data = onlyThisData ? data : assign(payload, page, data);

      if (nav.brave && !onlyThisData) data.brave = trueVar;
      if (nav._duckduckgoloader_ && !onlyThisData) data.duck = trueVar;


      var image = new Image();
      image.src =
        fullApiUrl +
        "/simple.gif?" +
        Object.keys(data)
          .filter(function (key) {
            return data[key] != undefinedVar;
          })
          .map(function (key) {
            return (
              encodeURIComponentFunc(key) +
              "=" +
              encodeURIComponentFunc(data[key])
            );
          })
          .join("&") +
        "&time=" +
        Date.now();
    };

    // Customers can overwrite their hostname, here we check for that
    var overwrittenHostname =
      overwriteOptions.hostname || attr(scriptElement, "hostname");
    var definedHostname = overwrittenHostname || locationHostname;

    var basePayload = {
      version: version,
      hostname: definedHostname,
    };


    /////////////////////
    // INITIALIZE VALUES
    //



    /////////////////////
    // GET SETTINGS
    //

    // Script mode, this can be hash mode for example
    var mode = overwriteOptions.mode || attr(scriptElement, "mode");









    // This code could error on (incomplete) implementations, that's why we use try...catch
    var timezone;
    try {
      // c = countries
      timezone = collectMetricByString("c")
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : undefinedVar;
    } catch (error) {
      warn(error);
    }

    /////////////////////
    // PAYLOAD FOR BOTH PAGE VIEWS AND EVENTS
    //

    var bot = isBotAgent;

    // t = timeonpage, scro = scrolled
    var collectDataOnLeave =
      collectMetricByString("t") || collectMetricByString("scro");

    if (bot) basePayload.bot = trueVar;

    var payload = assign(basePayload, {
      // us = useragent
      ua: collectMetricByString("us") ? userAgent : undefinedVar,

      https: loc.protocol == https,
      timezone: timezone,
      page_id: collectDataOnLeave ? uuid() : undefinedVar,

      // se = sessions
      session_id: collectMetricByString("se") ? uuid() : undefinedVar,
    });

    payload.sri = falseVar;

    // Use User-Agent Client Hints for better privacy
    // https://web.dev/user-agent-client-hints/
    if (uaData) {
      payload.mobile = uaData.mobile;
      payload.brands = stringify(uaData.brands);
    }

    /////////////////////
    // ADD WARNINGS
    //



    // When a customer overwrites the hostname, we need to know what the original
    // hostname was to hide that domain from referrer traffic
    if (definedHostname !== locationHostname)
      payload.hostname_original = locationHostname;

    // Don't track when Do Not Track is set to true
    if (doNotTrack in nav && nav[doNotTrack] == "1")
      return warn(
        notSendingWhen + doNotTrack + " is enabled. See " + docsUrl + "/dnt"
      );

    // Warn when sending from localhost and not having a hostname set
    if (
      (locationHostname.indexOf(".") == -1 ||
        /^[0-9.:]+$/.test(locationHostname)) &&
      !overwrittenHostname
    )
      warn(
        "Set hostname on " +
          locationHostname +
          ". See " +
          docsUrl +
          "/overwrite-domain-name"
      );

    /////////////////////
    // SETUP INITIAL VARIABLES
    //

    var page = {};
    var lastSendPath;

    var getReferrer = function () {
      return (
        (doc.referrer || "")
          .replace(locationHostname, definedHostname)
          .replace(/^https?:\/\/((m|l|w{2,3}([0-9]+)?)\.)?([^?#]+)(.*)$/, "$4")
          .replace(/^([^/]+)$/, "$1") || undefinedVar
      );
    };

    // We don't want to end up with sensitive data so we clean the referrer URL
    var referrer = getReferrer();

    /////////////////////
    // TIME ON PAGE AND SCROLLED LOGIC
    //

    // We don't put msHidden in if duration block, because it's used outside of that functionality
    var msHidden = 0;

    var sendOnLeave = function (id, push) {
      if (!collectDataOnLeave) return;

      var append = assign(basePayload, {
        type: "append",
        original_id: push ? id : payload.page_id,
      });



      if (push || !nav.sendBeacon) {
        // sendData will assign payload to request
        sendData(append, undefinedVar, trueVar);
      } else {
        nav.sendBeacon(fullApiUrl + "/append", stringify(append));
      }
    };


    addEventListenerFunc(pagehide, sendOnLeave, falseVar);


    /////////////////////
    // ACTUAL PAGE VIEW LOGIC
    //

    var getPath = function (overwrite) {
      var path = "";

      // decodeURIComponent can fail when having invalid characters
      // https://github.com/simpleanalytics/roadmap/issues/462
      try {
        path = overwrite || decodeURIComponentFunc(loc.pathname);
      } catch (error) {
        warn(error);
      }




      return path;
    };

    var previousReferrer;

    // Send page view and append data to it
    var sendPageView = function (
      isPushState,
      deleteSourceInfo,
      sameSite,
      metadata
    ) {
      if (isPushState) sendOnLeave("" + payload.page_id, trueVar);
      if (collectDataOnLeave) payload.page_id = uuid();

      var currentPage = definedHostname + getPath();

      sendData({
        id: payload.page_id,
        type: pageviewText,
        referrer: !deleteSourceInfo || sameSite ? referrer : null,
        query: getQueryParams(deleteSourceInfo),

      });

      previousReferrer = referrer;
      referrer = currentPage;

      pages++;
    };

    var sameSite, userNavigated;

    var pageview = function (isPushState, pathOverwrite, metadata) {
      // Obfuscate personal data in URL by dropping the search and hash
      var path = getPath(pathOverwrite);

      // Don't send the last path again (this could happen when pushState is used to change the path hash or search)
      if (!path || lastSendPath == path) return;

      lastSendPath = path;
      page.path = path;


      // l = language
      if (collectMetricByString("l")) {
        if (nav[language]) page[language] = nav[language];
      }


      // If a user does refresh we need to delete the referrer because otherwise it count double
      var perf = window.performance;
      var navigationText = "navigation";

      // Check if back, forward or reload buttons are being used in modern browsers
      var performaceEntryType;
      try {
        performaceEntryType = perf.getEntriesByType(navigationText)[0].type;
      } catch (error) {
        warn(error);
      }

      userNavigated = performaceEntryType
        ? ["reload", "back_forward"].indexOf(performaceEntryType) > -1
        : // Check if back, forward or reload buttons are being use in older browsers
          // 1: TYPE_RELOAD, 2: TYPE_BACK_FORWARD
          perf &&
          perf[navigationText] &&
          [1, 2].indexOf(perf[navigationText].type) > -1;

      // Check if referrer is the same as current real hostname (not the defined hostname!)
      sameSite = referrer
        ? referrer.split(slash)[0] == locationHostname
        : falseVar;



      var triggerSendPageView = function () {
        fetchedHighEntropyValues = trueVar;
        sendPageView(
          isPushState,
          isPushState || userNavigated || !collectMetricByString("r"), // r = referrers
          sameSite,
          metadata
        );
      };

      if (!fetchedHighEntropyValues) {
        // Request platform information if this is available
        try {
          if (uaData && isFunction(uaData.getHighEntropyValues)) {
            uaData
              .getHighEntropyValues([platformText, platformVersionText])
              .then(function (highEntropyValues) {
                payload.os_name = highEntropyValues[platformText];
                payload.os_version = highEntropyValues[platformVersionText];
                triggerSendPageView();
              })
              .catch(triggerSendPageView);
          } else {
            triggerSendPageView();
          }
        } catch (e) {
          triggerSendPageView();
        }
      } else {
        triggerSendPageView();
      }
    };



    pageview();

  } catch (e) {
    warn(e);
  }
})(
  window,
  {},
  "simpleanalyticscdn.com",
  "queue.",
  "cdn_light_11",
  "sa"
);
